<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
      nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
      wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
    </p>

    <div class="form-container--small">
      <label class="mt-4">
        Coupon Name
      </label>
      <b-form-input placeholder="Type here..."></b-form-input>

      <label class="mt-4-5">
        Coupon Code
      </label>
      <b-form-input placeholder="Type here..."></b-form-input>
    </div>
    <div class="form-container">
      <label class="mt-4-5">
        Coupon Description
      </label>
      <vue-editor v-model="coupon.description"></vue-editor>
    </div>

    <div class="form-container--small">
      <label class="mt-4-5">
        Coupon Type
      </label>
      <b-form-select
        v-model="coupon.type"
        :options="couponTypes"
      ></b-form-select>
      <b-input-group append="%" class="mt-4">
        <b-form-input placeholder="Type here..."></b-form-input>
      </b-input-group>

      <label class="mt-4-5">
        Coupon Expire Date
      </label>
      <b-form-input placeholder="Pick a day" type="date"></b-form-input>

      <label class="mt-4-5">
        Product Type
      </label>
      <b-form-select
        v-model="productType"
        :options="productTypes"
      ></b-form-select>

      <label class="mt-4-5">
        Pilih Produk
      </label>
      <b-form-select
        v-model="pilihProduct"
        :options="pilihProducts"
      ></b-form-select>

      <p class="mt-3">Produk yang dipilih:</p>

      <label class="mt-3">
        Pilih Kategori
      </label>
      <b-form-select v-model="category" :options="categories"></b-form-select>
      <p class="mt-3">Kategori yang dipilih:</p>

      <label class="mt-3">
        Jumlah Kupon
      </label>
      <b-form-input value="0"></b-form-input>

      <my-button type="blue" size="lg" class="mt-4-5" rounded block>
        SIMPAN KUPON
      </my-button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    couponTypes: [
      {
        text: "--Select One--",
        value: ""
      },
      {
        text: "Percentage Discount",
        value: "percentage"
      }
    ],
    coupon: {
      description: "",
      type: "percentage"
    },

    productTypes: [
      {
        text: "--Select One--",
        value: ""
      },
      {
        text: "Product & Category",
        value: "Product & Category"
      }
    ],

    productType: "Product & Category",

    pilihProducts: [
      {
        text: "--Select One--",
        value: ""
      },
      {
        text: "Search product",
        value: "Search product"
      }
    ],

    pilihProduct: "Search product",

    categories: [
      {
        text: "--Select One--",
        value: ""
      },
      {
        text: "Search Category",
        value: "Search Category"
      }
    ],

    category: "Search Category"
  })
};
</script>
